<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item link :to="{ name: 'app:home' }">
          <v-list-item-content>
            <v-list-item-title class="text-wrap">Início</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Administrador'"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="{ name: 'app:admin:usuario' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Usuários</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:admin:unidade_organizacional' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Unidades Organizacionais</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:admin:natureza_despesa' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Naturezas de Despesa</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:admin:categoria' }">
            <v-list-item-content>
              <v-list-item-title>Categorias</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:admin:item' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Materiais e Serviços</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:admin:habilidade' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Habilidades dos Servidores de TI</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:admin:template' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Modelos Minuta PDTIC</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:admin:pdti' }">
            <v-list-item-content>
              <v-list-item-title>PDTIC</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Equipe de Elaboração'"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="{ name: 'app:eqpdti:documento_referencia' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Documentos de Referência</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:principio_diretriz' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Princípios e Diretrizes</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:secao' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Minuta do PDTI</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: 'app:eqpdti:planejamento_estrategico' }"
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Planejamento Estratégico</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:objetivo_estrategico' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Objetivos Estratégicos</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'app:eqpdti:swot' }">
            <v-list-item-content>
              <v-list-item-title>Matriz SWOT</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'app:eqpdti:necessidade' }">
            <v-list-item-content>
              <v-list-item-title>Necessidades</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="'Equipe Local'"></v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- TODO: Este menu foi ocultado pois faz referência a inserção das informações do PDTIC antes de se ter o sistema. -->
          <!-- <v-list-item link :to="{ name: 'app:eqpdti:pdtic_anterior' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Resultados do PDTIC anterior</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link :to="{ name: 'app:eqpdti:resultados_metas' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Resultados do PDTIC anterior</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:contrato' }">
            <v-list-item-content>
              <v-list-item-title>Contratos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:inventario' }">
            <v-list-item-content>
              <v-list-item-title>Inventário</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:meta' }">
            <v-list-item-content>
              <v-list-item-title>Metas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:acao' }">
            <v-list-item-content>
              <v-list-item-title>Ações</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:demanda' }">
            <v-list-item-content>
              <v-list-item-title>Compras</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'app:eqpdti:recursos_humanos' }">
            <v-list-item-content>
              <v-list-item-title>Recursos Humanos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'app:eqpdti:solicitacao_item' }">
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Solicitação de Materiais e Serviços</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item link :to="{ name: 'app:eqpdti:relatorios' }">
          <v-list-item-content>
            <v-list-item-title class="text-wrap"
              >Relatórios</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-select
            :items="perfis"
            label="Perfil em uso"
            v-model="role"
          ></v-select>
          <v-switch label="Modo escuro" v-model="$state.dark"></v-switch>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app flat color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-toolbar-title>
        <img
          v-if="$vuetify.breakpoint.mobile"
          src="@/assets/img/logo_sem_texto.svg"
          height="48"
          alt="IFMT"
        />
        <img v-else src="@/assets/img/logo.svg" height="48" alt="IFMT" />
      </v-toolbar-title>
      <v-spacer />
      <h2 v-if="$vuetify.breakpoint.mobile">SGPDTIC</h2>
      <h1 v-else>SISTEMA GERENCIADOR PDTIC</h1>

      <v-spacer />
      <v-toolbar-items>
        <v-menu offset-y origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn large text slot="activator" v-on="on">
              <h2 class="font-weight-black">
                {{ $state.user.first_name }}
              </h2>
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item link :to="{ name: 'app:logout' }">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-app-bar :bottom="true" height="20" dark absolute>
      <v-spacer />
      <span v-if="pdtic != null">
        PDTIC {{ pdtic.inicio }} - {{ pdtic.fim }} ({{ pdtic.descricao }})
      </span>
      <v-spacer />
      versão: {{ commit_short || "local" }}
    </v-app-bar>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import { PDTIC } from "@/api/core";
export default {
  props: {
    source: String,
  },
  watch: {
    role(value) {
      this.$state.role = value;
    },
    "$state.dark": {
      immediate: true,
      handler(val) {
        this.$vuetify.theme.dark = val;
      },
    },
  },
  data() {
    return {
      drawer: null,
      role: this.$state.role,
      commit_short: process.env.VUE_APP_COMMIT_SHORT_SHA,
      pdtic: null,
      fab: false,
    };
  },
  computed: {
    perfis() {
      let r = [];
      if (this.$state.user.is_comissao) {
        r.push({
          value: "comissao",
          text: "Comitê de Governança Digital",
        });
      }
      if (this.$state.user.is_eqpdti) {
        r.push({
          value: "eqpdti",
          text: "Equipe de Elaboração",
        });
      }
      if (this.$state.user.is_eqpdti_uo) {
        r.push({
          value: "eqpdtiuo",
          text: "Equipe local",
        });
      }
      if (this.$state.user.is_superuser) {
        r.push({
          value: "superuser",
          text: "Administrador",
        });
      }
      return r;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  mounted() {
    PDTIC.aberto().then(({ data }) => {
      this.pdtic = data;
    });
  },
};
</script>
